body {
    font-family: var(--pds-base-type-font-family-100)
}

.timeline {
    height: 500px;
}

.flex-gap {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

pds-layout-container {
    padding: 32px 0 32px;
}

hr {
    margin: 32px 0 32px;
}
