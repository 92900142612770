.pds-tab-button {
    font-family: "FS Elliot Web Regular", Arial, arial, sans-serif !important;
}

#announcement {
    border: none
}

#announcement div {
    border: none
}

#announcement .container > div {
    padding-top: 0;
    padding-bottom: 0;
}

.text-center {
    text-align: center
}
